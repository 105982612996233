.breadcrumb {
  font-size: 14px;
  padding: 0px 139px;
  color: #FFFFFF;
  font-family: 'Ubuntu';
  font-weight: 700;
}

.textImage {
  padding: 0px 139px;
}

.hero-section {
  padding-top: 100px;
  background-size: 100%;
  background-position: bottom center;
  width: 100%;
  height: 70vh;
  background-color: rgba(255, 255, 255, 0.8);
  background-repeat: no-repeat;
}

.home {
  color: #94A3B8;
}


.hero-section h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 16px;
}

.message-therapy {
  padding: 0px 139px;
  width: 30%;
}

.massage {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  line-height: 56px;
  color: #FFFFFF;
  font-family: 'Ubuntu';
}

.massage + div {
  color: white;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Ubuntu';
}

.top-massage-container {
  padding: 0px 139px;
  margin-top: 78px;
}

.top-massage > p {
  font-size: 36px;
  font-weight: 700;
  color: #0F172A;
}

.top-massage-lorem > p {
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.grid-div {
  display: grid;
  margin-top: 30px;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.grid-div > div {

  background-color: #FFFFFF;
  border-radius: 5px;

}

.book_now {
  background-color: #E8F6F4;
  color: #398378;

  font-size: 16px;
  width: 90%;
  font-weight: 700;
  padding: 10px 16px;
  border-radius: 8px;
  border-style: hidden;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 20px;
  bottom: 16px;
  left: 17px
}

.book_now_container > .image_venue {
  width: 100%;
  height: 50%;
  max-height: 337px;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.book_now_div {
  text-align: center;
}

.book_now_container {
  position: relative;
}

.Textlabel {
  color: black;
  font-size: 16px;
  font-weight: 700;
}

.canada {
  color: #334155;
  font-size: 14px;
  font-weight: 400;
}

.star_rating {
  display: flex;
  flex-direction: row;
  margin-top: -3px;
}

.star_rating > img {
  width: 24px;
}

.star_rating > p {
  color: #64748B;
  font-size: 10px;
  font-weight: 400;
  margin-left: 5px;
}

.starRating_below > p {
  color: #334155;
  font-size: 14px;
  font-weight: 400;
}

.Deep_Cleansing {
  background-color: #398378;
  padding: 11px 28px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 22px;
}

.Deep_Cleansing > p {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 78px 0px;
}

.pagination_number {
  display: flex;
}

.pagination_number > p {
  margin-right: 10px;
}

.pagination_number > p:first-child {
  color: #398378;
  font-weight: 700;
}

.pagination_number > p:nth-child(2),
.pagination_number > p:nth-child(4) {
  color: #0F172A;
  text-decoration: underline;
  font-weight: 400;
}



.pagination > div > button {
  border: none;
  background-color: #CBD5E1;
}
